html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

*,
::before,
::after {
  box-sizing: border-box;
}

header {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header h1,
header h3 {
  margin: 0;
}

header h3 {
  margin-top: 0.5em;
}

.chart {
  max-width: 70vw;
  height: 50vh;
  margin: 4em auto;
}

@media screen and (max-width: 400px) {
  header {
    padding: 1em;
  }

  .chart {
    margin-top: 2em;
    max-width: 95vw;
    height: 25vh;
  }
}